export default function loopDestroy() {
  let swiper = this;
  let { $wrapperEl, params, slides } = swiper;
  $wrapperEl
    .children(
      `.${params.slideClass}.${params.slideDuplicateClass},.${params.slideClass}.${params.slideBlankClass}`,
    )
    .remove();
  slides.removeAttr('data-swiper-slide-index');
}
