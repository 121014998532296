/* eslint no-unused-vars: "off" */
export default function slideToClosest(
  speed = this.params.speed,
  runCallbacks = true,
  internal,
  threshold = 0.5,
) {
  let swiper = this;
  let index = swiper.activeIndex;
  let skip = Math.min(swiper.params.slidesPerGroupSkip, index);
  let snapIndex = skip + Math.floor((index - skip) / swiper.params.slidesPerGroup);

  let translate = swiper.rtlTranslate ? swiper.translate : -swiper.translate;

  if (translate >= swiper.snapGrid[snapIndex]) {
    // The current translate is on or after the current snap index, so the choice
    // is between the current index and the one after it.
    let currentSnap = swiper.snapGrid[snapIndex];
    let nextSnap = swiper.snapGrid[snapIndex + 1];
    if (translate - currentSnap > (nextSnap - currentSnap) * threshold) {
      index += swiper.params.slidesPerGroup;
    }
  } else {
    // The current translate is before the current snap index, so the choice
    // is between the current index and the one before it.
    let prevSnap = swiper.snapGrid[snapIndex - 1];
    let currentSnap = swiper.snapGrid[snapIndex];
    if (translate - prevSnap <= (currentSnap - prevSnap) * threshold) {
      index -= swiper.params.slidesPerGroup;
    }
  }
  index = Math.max(index, 0);
  index = Math.min(index, swiper.slidesGrid.length - 1);

  return swiper.slideTo(index, speed, runCallbacks, internal);
}
