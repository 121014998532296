import transitionEmit from './transitionEmit.js';

export default function transitionStart(runCallbacks = true, direction) {
  let swiper = this;
  let { params } = swiper;
  if (params.cssMode) return;
  if (params.autoHeight) {
    swiper.updateAutoHeight();
  }

  transitionEmit({ swiper, runCallbacks, direction, step: 'Start' });
}
